import { TermsProps } from '../../@interface/terms'

export const dataTerms: TermsProps = {
  title: 'Termos e Condições Gerais de Uso',
  subtitle: 'Gerais de Uso',
  update: '23 de janeiro de 2025',
  about: {
    title: 'Termos e Condições Gerais de Uso',
    introduction: `
      Este documento (“Termos de Uso”) apresenta as condições de uso
      dos serviços relacionados à Conta Cora (“Serviços”) prestados
      pela Cora Sociedade de Crédito, Financiamento e Investimento S.A. – “CORA SCFI”,
      sociedade com sede na  R. Frei Caneca, 1246, 3º andar, sala 31,
      Consolação, São Paulo - SP, CEP 01307-002, inscrita no
      Cadastro Nacional da Pessoa Jurídica do Ministério da Economia
      (“CNPJ/ME”) sob o no 37.880.206/0001-63 (“CORA” ou “Nós”).
      <br/>
      Ao utilizar-se dos Serviços oferecidos por Nós, você celebra um
      contrato conosco e assume os direitos e obrigações que
      apresentamos ao longo destes Termos de Uso.
      <br/>
      Você deve ler atentamente estes Termos de Uso, por inteiro, pois
      a utilização dos Serviços implica sua total concordância com as
      disposições deste documento. Caso não concorde com qualquer
      disposição deste instrumento, não utilize os Serviços.
      <br/>
      Para facilitar a leitura e compreensão dos Termos de Uso, apresentamos um resumo do conteúdo deste documento:`,
  },
  section: [
    {
      list: [
        {
          head: 'Definições Importantes',
          intro:
            'Nestes Termos de Uso, os termos abaixo iniciados em letras maiúsculas deverão ter o seguinte significado:',
          about:
            'Aqui explicamos quais são os requisitos para cadastro, para que você possa abrir sua Conta CORA rapidamente.',
          items: [
            {
              item: `<b>Aplicações</b>: são aplicações de internet (como, por exemplo, site ou aplicativo mobile) ou outras ferramentas disponibilizadas pela CORA para que você possa gerenciar a Conta CORA de sua empresa e utilizar os Serviços.`,
            },
            {
              item: `<b>Bandeira</b>: é a Visa do Brasil Empreendimentos Ltda., instituidora do arranjo de pagamento que viabiliza o uso do Cartão nos Estabelecimentos e do qual a CORA participa como emissor do Cartão.`,
            },
            {
              item: `<b>Cartão:</b> Compreende-se como Cartão toda modalidade emitida pela Cora, seja pré-pago, físico ou virtual, que opera na função débito e na função crédito, a ser recebido por sua Empresa, conforme solicitação - para a função crédito, após análise e aprovação de crédito, conforme <a href="/termos-de-uso-cartao-de-credito" alt="Termos de Uso do Cartão de Crédito">Termos de Uso do Cartão de Crédito Cora</a>.
              `,
            },
            {
              item: `<b>Disputa:</b> é o processo de contestação de transações que foram realizadas no cartão de débito ou crédito, em estabelecimento virtual ou físico, e abrange as 3 modalidades a seguir:`,
              child: [
                {
                  item: `<b>Fraude:</b> hipótese em que a Sua Empresa afirma não ter realizado e/ou autorizado a transação;`,
                },
                {
                  item: `<b>Erro:</b> existência comprovada de falha na autorização ou processamento da transação;`,
                },
                {
                  item: `<b>Desacordo comercial:</b> hipótese em que houve o descumprimento do acordo firmado no ato da compra entre a Sua Empresa e o estabelecimento beneficiário da transação.`,
                },
              ],
            },

            {
              item: `
              <b>Canais de Comunicação:</b> são os canais oficiais para comunicação entre a CORA e você, para temas referentes ao uso dos Serviços aqui apresentados:
              <br/>
              E-mail de atendimento: <a href="mailto:meajuda@cora.com.br">meajuda@cora.com.br</a> (o tempo de resposta para contatos via e-mail é de até 3 (três) dias úteis)
              <br/>
              Whatsapp oficial: <a href="https://api.whatsapp.com/send?phone=5511933058198" target="_blank">(11) 93305-8198</a>.
              <br/>
              Chat do aplicativo, para contestação de transações e dúvidas sobre os nossos serviços.
              <br/>
              O atendimento nos canais acima é disponibilizado 24h por dia, 7 dias por semana.
              <br/>
              Atendimento telefônico: <a href="tel:1150395715">(11) 5039-5715</a> e <a href="tel:08005912350">0800 591 2350</a>, todos os dias, das 9h às 17h (horário de Brasília), para pedidos de cancelamento e reclamações.
              <br/>
              Em caso de perda ou roubo do celular ou cartão Cora o atendimento é 24h.
              `,
            },
            {
              item: `<b>Conta CORA:</b> é a conta de pagamento digital pré-paga destinada à realização de transações de pagamento.`,
            },
            {
              item: `<b>Informações:</b> São todos os dados, informações, esclarecimentos e documentos relativos à sua empresa e fornecidos por você ou por outros usuários cadastrados, seja no momento do cadastro para acesso aos Serviços ou ao utilizar os Serviços.`,
            },
            {
              item: `<b>Estabelecimento:</b> qualquer fornecedor de produtos e/ou serviços situado no Brasil ou no exterior, que esteja habilitado a aceitar pagamentos com o Cartão, em lojas físicas ou por meio da internet.`,
            },
            {
              item: `<b>Parceira:</b> empresas terceiras contratadas pela CORA que auxiliam ou viabilizam a prestação dos Serviços ou, ainda, empresas terceiras (integrantes do grupo econômico CORA ou não) que poderão oferecer a Sua Empresa produtos e serviços relacionados ou não à CORA.`,
            },
            {
              item: `<b>Política de Privacidade:</b> é o instrumento contratual que disciplina a coleta, uso, armazenamento, tratamento e segurança das informações pessoais dos Usuários, que formaliza nosso respeito por sua privacidade e que está disponível para acesso em: <a href="/politica-privacidade">www.cora.com.br/politica-privacidade</a> A Política de Privacidade é parte integrante e inseparável destes Termos de Uso, de forma que a aceitação destes Termos de Uso implica, necessariamente, a aceitação da Política de Privacidade. <br/><b>Por este motivo, você deverá ler atentamente o referido documento.</b>`,
            },
            {
              item: `<b>Senha:</b> palavra-passe inserida por meio de digitação ou outro método de validação pessoal utilizado para acesso à Conta CORA.`,
            },
            {
              item: `<b>Sua Empresa:</b> pessoa jurídica constituída no Brasil, devidamente inscrita na Receita Federal do Brasil, titular da Conta CORA, cujos representantes são autorizados a acessar as Aplicações e/ou utilizar os Serviços.
                `,
            },
            {
              item: `<b>Usuário:</b> pessoa física representante de Sua Empresa, com poderes atribuídos pelos documentos societários de Sua Empresa, procuração.`,
            },
            {
              item: `<b>Você:</b> Usuário responsável pelo cadastro de Sua Empresa para acesso aos Serviços.`,
            },
            {
              item: `<b>Recebível</b>. Direitos de crédito futuros, de Sua titularidade, perante as Credenciadoras e/ou Subcredenciadoras, pelas vendas de produtos ou serviços que você realizou no crédito e que ainda serão pagos a você.`,
            },
          ],
        },
        {
          head: 'Cadastro para acesso aos serviços',
          about:
            'Aqui explicamos quais são os requisitos para cadastro, para que você possa abrir sua Conta CORA rapidamente.',
          intro: '',
          items: [
            {
              item:
                'Sua Empresa poderá ter acesso aos Serviços contratando-os diretamente por meio das Aplicações. Para tanto, Sua Empresa deverá estar adequadamente representada por Você, nos termos dos documentos societários ou procuração relevantes.',
            },
            {
              item:
                'Para a contratação dos Serviços, é necessário que Você forneça as Informações de Sua Empresa solicitadas no momento de cadastro.',
              child: [
                {
                  item: `Você deverá, no momento de contratação dos Serviços, indicar à CORA quais as regras de governança de Sua Empresa, informando os termos e os limites refletidos em documentos societários ou procurações relevantes.`,
                },
                {
                  item: `Você poderá, a seu critério e responsabilidade, cadastrar Usuários autorizados a movimentar a Conta CORA de Sua Empresa, que terão senhas próprias para acesso às Aplicações (de caráter confidencial, exclusivo e intransferível) e uso dos Serviços e para os quais poderá ser solicitada a emissão de Cartões adicionais.`,
                },
                {
                  item: `Os Usuários cadastrados por Você deverão estar autorizados pelas regras de governança de Sua Empresa, apresentadas no momento de contratação dos Serviços, a movimentar a Conta CORA de Sua Empresa e/ou a utilizar os Serviços.`,
                },
                {
                  item: `Você, como representante legal de Sua Empresa, poderá cadastrar quais transações não vinculadas ao uso do Cartão podem, de acordo com as regras de governança de Sua Empresa, ser realizadas isoladamente por Você, sem que seja necessária a obtenção de confirmação prévia por Usuários adicionais ou, ainda, o envio de Informações para confirmação de seus poderes (“Transações Autorizadas”).`,
                },
                {
                  item: `Ao realizar o seu cadastro para abrir uma Conta CORA, Você nos concede a autorização para consultar o SCR e bancos de dados referentes à Sua Empresa. Você está ciente e concorda que a(s) instituição(ões) financeira(s) responsáveis pela concessão de financiamentos à sua Empresa poderá(ão) consultar os débitos e responsabilidades decorrentes de operações com características de crédito e as informações e registros de medidas judiciais que existam em nome da sua Empresa junto ao Sistema de Informações de Crédito (SCR), gerido pelo Banco Central do Brasil, ou sistemas que venham a complementá-lo ou a substituí-lo, pois essa consulta é essencial para a análise e concessão de financiamentos no âmbito do Cartão de Crédito CORA.`,
                },
                {
                  item: `As instituições mencionadas na Cláusula acima poderão ainda fornecer ao Banco Central do Brasil informações sobre as operações de financiamento contratadas pela sua Empresa, incluindo o montante de suas dívidas a vencer e vencidas, inclusive as em atraso e as operações baixadas com prejuízo.`,
                },
                {
                  item: `Você reconhece que: (i) o SCR tem por finalidades fornecer informações ao Banco Central do Brasil para fins de supervisão do risco de crédito a que estão expostas as instituições financeiras e propiciar o intercâmbio de informações entre essas instituições com o objetivo de subsidiar decisões de crédito e de negócios; (ii) o SCR é alimentado mensalmente pelas instituições financeiras, mediante coleta de informações sobre operações concedidas com valor igual ou superior a R$ 200,00 (duzentos reais), vencidas e vincendas, bem como sobre as fianças e avais prestados pelas instituições financeiras a seus clientes; (iii) as instituições financeiras são obrigadas por regulamentação do Banco Central do Brasil a enviar ao SCR informações das operações de crédito contratadas pela sua Empresa, e que você, Cliente CORA poderá ter acesso aos dados constantes nesse sistema por meio do Registrato – Extrato de Registro de Informações do Banco Central do Brasil, um sistema que fornece gratuitamente informações disponíveis em cadastros administrados pelo Banco Central do Brasil; (iv) as informações remetidas ao SCR são de exclusiva responsabilidade da instituição financeira remetente, sendo certo que solicitações de correções e exclusões e manifestações de discordância quanto às informações constantes no SCR deverão ser dirigidas por Você à instituição financeira remetente; e (v) mais informações sobre o SCR podem também ser obtidas em consulta ao ambiente virtual do Banco Central do Brasil(www.bcb.gov.br).`,
                },
                {
                  item: `A CORA, as Parceiras e as instituições financeiras responsáveis pelos financiamentos relacionados ao Cartão CORA poderão acessar dados e informações financeiras relativas à Sua Empresa e seu representante legal, junto a serviços de proteção de crédito, incluindo a SERASA – Centralização de Serviços dos Bancos S.A e quaisquer outros órgãos, bancos de dados, entidades ou empresas que julgar pertinentes, bem como trocar e incluir informações cadastrais, financeiras e de crédito a respeito do Cliente CORA nesses bancos de dados e junto a instituições financeiras e providenciar a abertura de cadastro visando à formação de histórico de crédito da sua Empresa.`,
                },
              ],
            },
          ],
        },
        {
          head: 'Funcionalidades da Conta CORA',
          about: 'Aqui detalhamos as funcionalidades da Conta CORA.',
          intro: '',
          items: [
            {
              item: `Aporte de recursos. Sua Empresa poderá acessar a Conta CORA e transferir recursos financeiros para ela a partir do pagamento de boleto, transferências bancárias, Pix ou por outras formas que venham a ser disponibilizadas pela CORA.`,
            },
            {
              item: `Funcionalidades. Os recursos alocados na Conta CORA poderão ser utilizados para as seguintes operações, sempre que houver disponibilidade de saldo suficiente na Conta CORA e mediante a utilização de Senha ou conforme for o caso, por outros meios que caracterizam a sua expressa manifestação de vontade e concordância com a operação em questão, incluindo pagamentos realizados por meio da tecnologia “contactless” (aproximação):`,
              child: [
                {
                  item: `Compras em Estabelecimentos: o Cartão permite movimentar a Conta CORA e realizar a compra de bens ou serviços em Estabelecimentos que aceitem pagamentos em cartões da Bandeira;`,
                },
                {
                  item: `Saque: o Cartão permite movimentar a Conta CORA, de forma a retirar recursos em Caixas eletrônico 24 (vinte e quatro) Horas.`,
                },
                {
                  item: `Transferências entre Contas: Sua Empresa poderá transferir recursos financeiros, observados os prazos e procedimentos previstos pelo sistema financeiro brasileiro, da sua Conta CORA para Contas CORA, contas bancárias e outras contas de pagamentos (contas digitais);`,
                },
                {
                  item: `Pagamento de Boletos e Contas de Consumo: Sua Empresa poderá realizar pagamentos de boletos e contas de consumo de concessionárias conveniadas, sempre sujeito às limitações impostas pela legislação brasileira e pelas empresas emissoras dos respectivos títulos/boletos;`,
                },
                {
                  item: 'Pix:',
                  child: [
                    {
                      item: `Uma transação do Pix pode ser enviada das seguintes maneiras:
                      <br/>I) Inserção manual dos detalhes da conta do usuário recebedor;
                      <br/> II) Leitura de QR code;
                      <br/> III) Copiar e colar um código do Pix;
                      <br/> IV) Utilização de uma Chave Pix.`,
                    },
                    {
                      item: `Medidas de Segurança sobre os Dispositivos de Acesso ao Pix:
                      <br/>I) a quantidade de dispositivos que poderão ser habilitados para iniciação de transações PIX pelo cliente será estabelecida a exclusivo critério da Cora;
                      <br/> II) dentre as opções disponíveis para o gerenciamento dos dispositivos cadastrados na Sua Conta Cora estão: a inclusão, exclusão e bloqueio de dispositivos;
                      <br/> III) a Cora poderá excluir, independentemente de qualquer anuência do cliente, o(s) dispositivo(s) que estiver(em) cadastrado(s) na Sua Conta Cora nos casos em que: i)  tenha sido roubado ou perdido; ii) tenha sido danificado e não puder ser reparado; iii) esteja desatualizado e não seja mais compatível; iv) tenha a sua segurança comprometida; v) esteja sendo usado para atividades ilegais; ou vi) não tenha sido utilizado para iniciar uma transação Pix durante doze meses;
                      <br/> IV) os dispositivos não cadastrados na Sua Conta Cora não poderão fazer qualquer tipo de transação Pix.`,
                    },
                    {
                      item: `Funcionamento do Pix Agendado:
                      <br/>I) para que o Pix Agendado seja concretizado é preciso que Você tenha recursos suficientes em Sua Conta Cora no momento da consolidação da transação, observando eventuais limites transacionais para aquela determinada operação;
                      <br/> II) para os casos em que o Pix Agendado Recorrente for em dias que não existem no mês corrente, como por exemplo os dias 29, 30, 31, a transação será completada no primeiro dia do mês seguinte;
                      <br/> III) o Pix Agendado poderá ser cancelado até às 23h59 do dia anterior à data de agendamento da transação.`,
                    },
                  ],
                },
                {
                  item: `Emissão de Boletos: Sua Empresa poderá emitir boletos, cujo pagamento direcionará os recursos diretamente para sua Conta CORA;`,
                  child: [
                    {
                      item: `Após o 100º boleto compensado no mês, ou seja, a partir do 101º boleto pago pelo seu cliente, Sua Empresa terá a cobrança da tarifa de R$ 1,90 (um real e noventa centavos) por boleto compensado, a ser descontada diretamente da sua Conta CORA quando da efetiva compensação do boleto.`,
                    },
                  ],
                },
                {
                  item: `Nota Fiscal Integrada: Ao emitir uma cobrança pelo Aplicativo Cora ou pela CoraWeb, Você poderá selecionar a opção de emitir uma nota fiscal (NFSe) do serviço prestado diretamente na plataforma da Cora.`,
                  child: [
                    {
                      item: `Ao selecionar a opção de emitir uma nota fiscal, Você precisará passar por algumas etapas de configuração do serviço: (i) Primeiramente, será preciso enviar o certificado A1 de sua empresa, para que a Cora possa indicar à prefeitura que sua empresa é a responsável pelo registro da Nota; (ii) Após o envio do certificado, você precisará preencher algumas informações que vão compor a Nota, tais como: CNAE; códigos de serviço; alíquota do INSS, descrição do serviço, dentre outras informações necessárias para o correto preenchimento do documento.`,
                    },
                    {
                      item: 'Você terá a opção de emitir a NFSe no momento da emissão do boleto de cobrança ou quando receber o pagamento do serviço prestado. Independente da opção escolhida, logo após a emissão da Nota Fiscal, a Cora enviará um e-mail ao seu endereço eletrônico cadastrado com o arquivo em formato XML e PDF da Nota Fiscal. Você também pode acessar esses formatos na Área de Gestão da sua Conta Cora.'
                    },
                    {
                      item: 'A disponibilidade do serviço de Emissão de Nota Fiscal depende da localização de Sua Empresa, portanto, para verificar se o serviço está disponível na sua região, Você poderá consultar pelo seguinte link ou no Aplicativo Cora.'
                    },
                    {
                      item: 'Além da emissão da Nota Fiscal, será possível consultar e realizar o download das notas fiscais já emitidas, editá-las quando ainda não forem pagas e/ou apresentarem erro na emissão, e cancelá-las de acordo com a necessidade diretamente pelo Aplicativo Cora ou CoraWeb. A depender da cidade em que a NFSe for emitida, é preciso realizar o cancelamento diretamente pela sua prefeitura.'
                    },
                    {
                      item: 'O custo de emissão por Nota Fiscal de Serviço será de R$ 0,99 (noventa e nove centavos) e este valor será descontado diretamente da Sua Conta Cora, mensalmente, de acordo com a quantidade de Notas Fiscais emitidas durante o mês.'
                    },
                  ]
                },
                {
                  item: `<b>Débito Direto Autorizado (DDA).</b> Você poderá autorizar a exibição e a ordem de pagamento de quaisquer boletos emitidos contra a Sua Empresa por meio do DDA na sua Conta Cora. Uma vez que a visualização seja autorizada, a responsabilidade quanto ao pagamento e a verificação das informações dos boletos de cobranças lançados no DDA é da Sua Empresa. Os boletos elencados no DDA não podem ser alterados e/ou cancelados pela Cora, uma vez que o DDA é uma mera central de visualização de cobranças lançadas por outras empresas.`
                },
                {
                  item: `Você, como representante legal de Sua Empresa, fica pré-autorizado a realizar as Transações Autorizadas isoladamente sem que seja necessária a obtenção de qualquer confirmação prévia. Para as demais transações não vinculadas ao uso do Cartão, a realização das operações estará condicionada à confirmação por Usuários adicionais ou obtenção de Informações para confirmação de poderes.`,
                },
              ],
            },
            {
              item: `Limitações. Cada um dos serviços descritos acima pode ter limitações técnicas, Tarifas e especificações, tais como, mas não se limitando a, valores mínimos e/ou máximos para transações e quantidade de transações, conforme informações disponíveis nas Aplicações.`,
              child: [
                {
                  item: `Limitação Boletos de Depósito em Conta. Os boletos de depósito em Conta Cora serão limitados à emissão de 15 (quinze) por mês, no valor mínimo de R$ 100,00 (cem reais) e máximo de R$ 5.000,00 (cinco mil reais) em cada um.`,
                },
              ],
            },
          ],
        },
        {
          head: 'Regras de Uso da sua Conta CORA',
          about:
            'Aqui constam instruções de segurança e de usabilidade que você deve seguir para que possa utilizar livremente a sua Conta.',
          intro: '',
          items: [
            {
              item: `O Usuário se compromete a não utilizar os serviços da Conta CORA para a realização de pagamentos ou transferências relacionadas
              <br/>
              (I) a atividades ilegais, tais como, lavagem de dinheiro, estelionato, realização de fraudes contra terceiros, jogos de azar e apostas em geral,
              <br/>
              (II) à compra e venda de produtos e serviços considerados ilegais pela legislação brasileira;
              <br/>
              (III) à venda de produtos ou serviços identificados pelos órgãos governamentais como tendo uma alta probabilidade de serem fraudulentos ou que representem risco à segurança física e financeira do consumidor;
              <br/>
              (IV) a produtos que incentivem, promovam, facilitem ou ensinem pessoas a praticarem atividades ilegais,
              <br/>
              (V) à promoção de ódio, violência, racismo ou outras formas de intolerância discriminatória.`,
            },
            {
              item: `É sua responsabilidade manter seu sistema protegido contra vírus e outros <span style="font-style: italic">malwares</span>, bem como é sua obrigação proteger suas senhas e PIN de acesso à Conta CORA. Dessa forma, não nos responsabilizamos por danos causados por vírus e malwares em decorrência de acesso, utilização ou navegação no site na internet ou como consequência da transferência de dados, arquivos, imagens, textos ou áudio contidos no mesmo. É de sua responsabilidade a certificação de estar acessando sua conta em redes seguras.`,
            },
            {
              item: `Você deve manter o seu celular em local seguro e com mecanismos restritivos de acesso aos seus aplicativos, nunca as divulgando e nem permitindo o seu uso por terceiros não autorizados. Você isenta a CORA de qualquer responsabilidade pelo eventual acesso não autorizado de sua conta de e-mail, aplicativo e/ou número de telefone.`,
            },
            {
              item: `O usuário não deve adotar, no âmbito da relação com a CORA, bem como seus representantes e funcionários, condutas desrespeitosas, ofensivas, difamatórias, obscenas, ilegais ou realizar ameaças, bem como realizar a prática de SPAM ou qualquer tipo de atitudes contrárias às boas práticas da internet e das redes sociais. Podendo a CORA, a seu exclusivo critério, limitar o acesso à Conta ou excluir o Usuário nessas circunstâncias.`,
            },
            {
              item: `Você isenta a CORA de qualquer responsabilidade decorrente de (I) transações não realizadas em virtude da suspeita de crimes financeiros; (II) transações realizadas por terceiros com o uso do seu PIN e/ou senhas; (III) transações realizadas por terceiros não autorizados em decorrência da falta de informe de limitação imediata da sua conta do CORA, após furto, roubo e/ou perda do seu aparelho.`,
            },
            {
              item: `Nas operações que sejam suspeitas de fraude ou em que se verifique falhas operacionais nos sistemas das instituições envolvidas na transação do Pix, o cliente CORA autoriza a devolução de tais valores, bem como o bloqueio dos recursos mantidos na Conta Cora, limitando-a até o atingimento do valor total da transação recebida para posterior devolução dos recursos envolvidos na operação do Pix.`,
            },
          ],
        },
        {
          head: 'Operação de Desconto',
          about: 'Aqui trazemos informações sobre Operações de Desconto',
          intro: '',
          items: [
            {
              item: `Operação de Desconto de Recebíveis. Operação de cessão definitiva dos Recebíveis futuros decorrentes de vendas realizadas no crédito que Sua Empresa pode realizar com a Cora. Sua Empresa recebe antes, da Cora, aquilo que só receberia depois das Credenciadoras e/ou Subcredenciadoras e, em contrapartida, cede o direito de recebimento posterior, perante as Credenciadoras e/ou Subcredenciadoras, para a Cora.`,
            },
            {
              item: `A Operação de Desconto de Recebíveis depende da prévia análise da sua agenda de recebíveis.`,
              child: [
                {
                  item: `Agenda de Recebíveis. Demonstrativo de todos os Recebíveis da Sua Empresa por transações realizadas que serão liquidadas com Credenciadoras e/ou Subcredenciadoras com as quais Sua Empresa tem relacionamento, incluindo histórico de vendas, valores a receber e Recebíveis livres para serem objeto de Operação de Desconto.`,
                },
              ],
            },
            {
              item: `Você autoriza, por tempo indeterminado,  a CORA a verificar, por si ou através de suas Parceiras, a qualquer momento, sua agenda de recebíveis.`,
            },
            {
              item: `Você autoriza, por tempo indeterminado,  a CORA a consultar, por si ou através de suas Parceiras, a qualquer momento, o histórico de faturamento relativo a recebíveis decorrentes de transações com cartões do período de 12 meses anteriores à data de cada consulta da Sua Empresa (arquivo AACG0001).`,
            },
            {
              item: `A Operação de Desconto ainda está em período de testes, e não está disponível para toda a base.`,
            },
          ],
        },
        {
          head: 'Uso do Cartão',
          about:
            'Aqui apresentamos as informações referentes ao desbloqueio e uso do Cartão, bem como o que você deve fazer se quiser contestar uma transação feita com o Cartão, entre outras questões.',
          intro: '',
          items: [
            {
              item: `Desbloqueio. Assim que receber o Cartão, Sua Empresa (Você ou outro Usuário cadastrado, conforme o caso) deverá conferir os dados do Cartão e realizar o desbloqueio por meio das Aplicações ou dos Canais de Comunicação.`,
            },
            {
              item: `Formalização. A utilização do Cartão é formalizada com o uso da Senha ou, conforme o caso, por outros meios que caracterizam a sua expressa manifestação de vontade e concordância com a operação em questão, incluindo, sem limitação, pagamentos realizados por meio da tecnologia contactless.`,
            },
            {
              item: `Substituição. A CORA poderá, a seu único e exclusivo critério, substituir o Cartão de Sua Empresa e/ou seus adicionais por Cartão equivalente ou superior, sendo-lhe facultado, inclusive, alterar a Bandeira do referido Cartão. Sempre que possível, a CORA comunicará referida substituição com antecedência mínima de 30 (trinta) dias.`,
            },
            {
              item: `Limitação preventiva. Como medida de segurança, a CORA poderá limitar preventivamente os Cartões e/ou a Conta CORA de sua Empresa caso verifique operações que fujam ao seu padrão de uso e/ou verifique a necessidade de envio de quaisquer Informações.`,
            },
            {
              item: `Compras à vista e saque. Sua Empresa poderá utilizar seu Cartão no território nacional ou no exterior, para movimentação dos recursos mantidos em sua Conta CORA, de forma a realizar a compra de bens e/ou serviços nos Estabelecimentos e a retirada de recursos (saque) nas redes credenciadas, observado o valor disponível em sua Conta CORA e o período de validade impresso no seu Cartão.`,
              child: [
                {
                  item: `Restrição preventiva. Fica desde já estabelecido que a CORA, visando à segurança de Sua Empresa e para evitar o uso fraudulento do Cartão, poderá eventualmente limitar ou restringir o uso do Cartão.`,
                },
                {
                  item: `Cancelamento de compras. Em caso de cancelamento de qualquer compra, Sua Empresa deverá obter, no ato, o comprovante do cancelamento junto ao Estabelecimento.`,
                },
                {
                  item: `Conferência de despesas. Sua Empresa deverá conferir todas as despesas lançadas em sua Fatura e, caso discorde de algum lançamento, deverá dar início a um processo de Disputa.`,
                },
                {
                  item: `Limites de saques. De acordo com as regras de segurança da CORA e da rede credenciada os limites de saque do Cartão de Débito são de R$ 2.000,00 (dois mil reais) por saque e R$5.000,00 (cinco mil reais) por dia. Não sendo possível a retirada de recursos além do saldo previamente disponível em sua conta Cora`,
                },
                {
                  item: `Limites para pagamentos sem contato (contactless). As compras acima de R$200,00 (duzentos reais) deverão ser realizadas inserindo a senha do cartão.`,
                },
                {
                  item: `Tarifas. Para a realização de saques do Cartão de Débito será cobrada a tarifa de R$ 9,90 (nove reais e noventa centavos) por saque. A cobrança será realizada via débito em conta no momento em que o saque for efetuado. O lançamento do respectivo débito estará apontado no seu extrato, e servirá como comprovante de pagamento.`,
                },
                {
                  item: `Sua Empresa (Você ou outro Usuário cadastrado, conforme o caso) está ciente de que a utilização da Função Débito não acarreta na aprovação automática da utilização da Função Crédito, e dependerá de prévia análise e contratação específica do Emissor.`,
                },
              ],
            },
            {
              item: `Estabelecimentos. Sua Empresa compreende e concorda que a CORA não é responsável pela eventual restrição dos Estabelecimentos ao uso do Cartão ou pela entrega, qualidade, pela quantidade, por defeitos de bens ou serviços adquiridos, ou por qualquer diferença de preço. <br/><br/> Qualquer reclamação relacionada aos produtos ou serviços adquiridos com o Cartão deverá ser direcionada exclusivamente ao Estabelecimento.`,
            },
            {
              item: `Disputa. Caso Sua Empresa não reconheça um débito em sua Conta CORA que tenha sido realizado com uso do Cartão, Sua Empresa deverá entrar em contato conosco em até 60 dias corridos, contados da data da transação não reconhecida, e seguir as orientações para realização do procedimento de Disputa.`,
              child: [
                {
                  item: `A Disputa seguirá as regras estabelecidas pela Bandeira, pela CORA e/ou pelas Parceiras, de forma que apenas será possível abrir o processo de Disputa quando comprovado o erro ou o desacordo comercial que tenha gerado o débito indevido e não houver culpa exclusiva de Sua Empresa, nos termos das regras acima mencionadas.`,
                },
                {
                  item: `Uma vez aprovada a Disputa , o valor será creditado na Conta CORA de Sua Empresa, no prazo estabelecido pela Bandeira, pela CORA e/ou pelas Parceiras, conforme aplicável.`,
                },
                {
                  item: `Sua Empresa concorda e aceita que o procedimento de disputa somente ocorrerá para transações efetuadas com o uso do cartão. Não serão aceitos procedimentos de disputa para transações que sejam realizadas por meio das aplicações ou outros meios.`,
                },
              ],
            },
          ],
        },
        {
          head: `Cuidados com relação à Conta CORA e ao Cartão`,
          about: `Aqui explicamos quais seus deveres ao utilizar os Serviços e o que você deve fazer se algo ocorrer com o Cartão ou com a sua Conta CORA.`,
          intro: '',
          items: [
            {
              item: `Responsabilidade pelas Informações. Todas as Informações fornecidas pela Sua Empresa, inclusive àquelas relativas aos Usuários cadastrados, devem ser completas, atualizadas, exatas, precisas e verdadeiras.`,
              child: [
                {
                  item: `Você autoriza a CORA a verificar, por si ou através de suas Parceiras, a qualquer momento, a veracidade das Informações e solicitar, a seu exclusivo critério, esclarecimentos e documentação suporte adicionais que julgar necessária para a devida comprovação das informações prestadas.`,
                },
                {
                  item: `Caso seja verificado um cadastro feito a partir de Informações em desacordo com o item 7.1 ou ainda, não se possa detectar a identidade de Sua Empresa, esse cadastro será automaticamente suspenso até que a situação se regularize ou excluído. Caso o cadastro seja excluído, o acesso de Sua Empresa aos Serviços será definitivamente cancelado.`,
                },
                {
                  item: `É responsabilidade de Sua Empresa (Você ou outro Usuário cadastrado, conforme o caso) inserir os dados corretos ao realizar transferências entre contas CORA, contas bancárias e/ou outras contas de pagamentos (contas digitais). Qualquer erro, atraso, prejuízo ou dano causado devido ao cadastro de dados incorretos, incompletos ou desatualizados é de total responsabilidade de Sua Empresa.`,
                },
              ],
            },
            {
              item: `Responsabilidade pelo uso e pelo acesso. Sua Empresa é a única e exclusiva responsável por sua Conta CORA, pelo uso do Cartão, pelo uso dos Serviços e por qualquer atividade associada a ela, inclusive por Você e/ou Usuários autorizados.`,
              child: [
                {
                  item: `Atenção: a CORA não solicita ou exige a divulgação de senhas, dados de Cartão ou outras Informações por qualquer meio diferente dos Canais de Comunicação oficiais. Caso Sua Empresa receba qualquer comunicação com esse tipo de abordagem e conteúdo, deverá desconsiderar, não responder e, se possível, encaminhar o seu relato para nossos Canais de Comunicação.`,
                },
                {
                  item: `Guarda do cartão e proteção da senha. Sua Empresa (Você ou outro Usuário, conforme o caso) é a única responsável pelo uso e guarda do seu Cartão físico. Recomenda-se que:
                  (I) o Cartão seja guardado em local seguro, jamais permitindo seu uso por terceiros;
                  (II) sua senha seja memorizada e mantida em sigilo, evitando anotar ou guardar a senha em suportes físicos ou digitais;
                  (III) sua senha nunca seja anotada ou guardada em conjunto com o Cartão; e
                  (IV) sua senha seja forte, não relacionada a datas ou outras referências pessoais de Sua Empresa (Você ou outro Usuário, conforme o caso), e seja trocada periodicamente.`,
                },
                {
                  item: `Atualizações na Plataforma. As Aplicações poderão ser atualizadas, a critério da CORA, caso em que suspensões temporárias nos Serviços poderão acontecer. Sua Empresa, Você e/ou Usuários se comprometem a sempre utilizar a versão mais atualizada disponível das Aplicações. A CORA poderá suspender o funcionamento de versões desatualizadas das Aplicações, em especial quando as atualizações conferirem melhorias de segurança.`,
                },
                {
                  item: `Sua Empresa será informada pela loja oficial de aplicativos do sistema operacional de seu dispositivo móvel sobre a disponibilidade de novas atualizações (conforme configurações de seu dispositivo móvel) cabendo exclusivamente a Sua Empresa baixar as versões atualizadas das Aplicações.`,
                },
                {
                  item: `Aplicativos de Terceiros. Atualmente, uma série de soluções oferecem a possibilidade de integração com contas de pagamento e outros serviços financeiros (“Aplicativos de Terceiros”), sendo que alguns desses Aplicativos de Terceiros poderão ter sua integração disponibilizada pela CORA e/ou pelas Parceiras. O uso desses Aplicativos de Terceiros é de única e exclusiva responsabilidade de Sua Empresa, que se obriga, ainda, a não integrar as Aplicações, sua Conta CORA ou seu Cartão a quaisquer Aplicativos de Terceiros que não sejam autorizados pela CORA, pelas Parceiras e/ou pela Bandeira.`,
                },
                {
                  item: `Os Aplicativos de Terceiros poderão ter seus próprios termos e condições de uso e políticas de privacidade. Sua Empresa compreende e concorda que a CORA, as Parceiras e a Bandeira não endossam e não são responsáveis pelo comportamento, recursos, conteúdo ou por qualquer transação que Sua Empresa possa fazer com o uso de Aplicativos de Terceiros.`,
                },
              ],
            },
            {
              item: `Perda, extravio, furto ou roubo do dispositivo (celular/computador) utilizado para o acesso às Aplicações. Em caso de perda, extravio, furto ou roubo do login ou Senha para acesso à Conta CORA de Sua Empresa, Você ou outro Usuário deve, imediatamente:`,
              child: [
                {
                  item: `Acessar as seções específicas disponibilizadas pela CORA nas Aplicações e redefinir a Senha; ou`,
                },
                {
                  item: `Somente diante da impossibilidade em proceder conforme indicado no item 7.3.1 acima, entrar em contato conosco, por meio do telefone indicado dentre os Canais de Comunicação ou chat eventualmente disponibilizado nas Aplicações, e solicitar a redefinição da Senha.`,
                },
                {
                  item: `Após os procedimentos indicados no item 7.3.1 ou 7.3.2 acima, o bloqueio do acesso à Conta CORA mediante uso da Senha perdida, extraviada, furtada ou roubada será efetivado pela CORA em até 24 (vinte e quatro) horas.
                  No caso do item 7.3.1, o acesso à Conta CORA de Sua Empresa poderá ser realizado mediante uso da Senha redefinida. No caso do item 7.3.2, o acesso à Conta CORA de Sua Empresa será temporariamente bloqueado até que Você e/ou os demais Usuários recebam novos dados de acesso.`,
                },
              ],
            },
            {
              item: `A CORA somente poderá ser responsabilizada pelo uso indevido da Conta CORA ou do Cartão por terceiros nos casos em que não promover o bloqueio no prazo mencionado no item 7.3.3 acima.`,
            },
            {
              item: `Perda, extravio, furto ou roubo de Cartão Débito. Em caso de perda, extravio, furto ou roubo de um Cartão de Débito CORA, bem como suspeita de fraude, falsificação ou utilização indevida por terceiros, Você ou outro Usuário deve, imediatamente:`,
              child: [
                {
                  item: `acessar as seções especificamente destinadas ao bloqueio do Cartão disponibilizada pela CORA nas Aplicações e realizar o bloqueio do Cartão; ou`,
                },
                {
                  item: `somente diante da impossibilidade em proceder conforme indicado no item 7.5.1 acima, entrar em contato com a CORA, por meio do telefone indicado nos Canais de Comunicação ou chat eventualmente disponibilizado nas Aplicações, e solicitar o bloqueio do Cartão.`,
                },
                {
                  item: `Após os procedimentos indicados nos itens 7.5.1 ou 7.5.2 acima, o cancelamento do Cartão perdido, extraviado, furtado ou roubado será efetivado pela CORA. Um novo Cartão poderá ser solicitado por meio do aplicativo da Conta CORA, sendo entregue de acordo com os prazos informados pelos Canais de Comunicação e pelo próprio Aplicativo.`,
                },
                {
                  item: `A Cora somente poderá ser responsabilizada pelo uso indevido do Cartão por terceiros nos casos em que não promover o bloqueio.`,
                },
              ],
            },
          ],
        },
        {
          head: `Segurança da sua Conta CORA`,
          about: `Aqui esclarecemos algumas práticas adotadas para verificação de segurança e regularização da sua Conta.`,
          intro: '',
          items: [
            {
              item: `A CORA poderá impor limitações ao uso, temporária ou definitivamente, bem como excluir a Conta CORA de um Usuário e realizar a extração dos valores obtidos indevidamente, a qualquer momento, além de tomar as medidas legais cabíveis se: (1) o Usuário não cumprir qualquer dispositivo deste Termos de Uso; (2) praticar atos fraudulentos, dolosos ou com evidente má-fé, a fim de obter vantagem indevida, incluindo ainda a obtenção de cashback por outras plataformas digitais; (3) se o Usuário não enviar informações e documentos solicitados para a verificação de segurança dentro do prazo estipulado; (4) identificar atos do Usuário realizados com a finalidade de induzir ao erro para obter vantagem manifestamente ilícita; (5) não puder ser verificada a identidade do Usuário ou qualquer informação fornecida por ele seja inverídica; e (6) alguma atitude do Usuário tiver o condão de causar danos a terceiros ou à CORA; `,
            },
            {
              item: `A CORA se reserva o direito de limitar ou excluir o acesso do Usuário aos serviços e recursos da sua Conta CORA ou aplicativo,
              por tempo indeterminado, não assistindo ao Usuário, por essa razão, qualquer tipo de indenização ou ressarcimento em caso de:
              `,
              child: [
                { item: `Violação aos termos destes Termos de Uso;` },
                { item: `Suspeita de fraude;` },
                { item: `Não fornecimento dos documentos solicitados;` },
                {
                  item: `Constatação de dados incorretos ou inverídicos fornecidos pelo Usuário;`,
                },
                {
                  item: `Condutas praticadas com a finalidade de obter saldo de forma ilícita e/ou fraudulenta;`,
                },
                {
                  item: `Envolvimento do Usuário em qualquer ato de corrupção, ativa ou passiva;`,
                },
                {
                  item: `Envolvimento em ações caracterizadas como e não se limitando à financiamento ao terrorismo, lavagem de dinheiro ou qualquer outra participação em crimes descritos em todas as legislações aplicáveis ao Usuário.`,
                },
              ],
            },
            {
              item: `Por razões de segurança dos Usuários da CORA e de terceiros, riscos de ações judiciais, ocorrência de Disputa e outras demandas contra a CORA, os pagamentos efetuados também poderão permanecer limitados, a critério da CORA, em caso de suspeita de qualquer ilegitimidade, fraude ou qualquer outro ato contrário às disposições dos presentes Termos e Condições de Uso e da legislação vigente. É assegurado, ainda, à CORA, ao seu exclusivo critério, o direito de bloquear a totalidade dos valores existentes na conta digital do Usuário para fins de apuração e verificação de questões relativas à idoneidade do Usuário; legalidade e legitimidade das transações realizadas; e/ou reclamações pendentes, reservando-se a CORA no direito de relatar as ocorrências aos órgãos e autoridades policiais competentes.`,
            },
            {
              item: `Como medida de segurança, caso decida utilizar outro smartphone que não aquele no qual previamente você tenha feito download do aplicativo, a CORA poderá solicitar a confirmação algumas informações com a finalidade de garantir a sua segurança. Caso a CORA entenda que não houve o atendimento das informações de forma correta a garantir sua identidade, poderá não autorizar a utilização da sua Conta CORA.`,
            },
            {
              item: `As transações do Pix estão sujeitas à análise de risco e exigem que a conta Cora do usuário pagador tenha saldo disponível suficiente para tal transação. Caso o usuário pagador não tenha saldo disponível suficiente na conta, a transação do Pix não será realizada. A CORA poderá definir limites para as transações do Pix e rejeitá-las, na medida permitida pela lei aplicável.`,
            },
            {
              item: `<b>Tendo por finalidade o tratamento e o compartilhamento de dados e informações sobre indícios de fraudes no âmbito da Resolução Conjunta nº 6 do Banco Central do Brasil, de 23 de maio de 2023, Sua Empresa consente, de forma prévia e geral, com o registro de quaisquer dados e informações que lhe digam respeito relativas a indícios de fraude, incluindo, mas não limitada a, aquele(a)s pertinentes à  i) identificação de quem, segundo indícios disponíveis, teria executado ou tentado executar uma fraude; ii) descrição dos indícios da ocorrência ou da tentativa de fraude; iii) identificação das instituição responsável pelo registro dos dados e informações e iv) identificação dos dados da conta destinatária e de seu titular, em caso de transferência ou pagamento de recursos.</b>`,
            },
          ],
        },
        {
          head: `Penalidades`,
          about: `Aqui tratamos de algumas condutas do Usuário que geram penalidades e restrições ao uso da Conta.`,
          intro: '',
          items: [
            {
              item: `Sua Conta do CORA poderá ser limitada, sem que seja permitida a realização de nenhuma movimentação, a qualquer momento caso haja qualquer suspeita de inconsistências cadastrais, autofinanciamento por meio de máquina de cartão e outros meios, crimes financeiros, operações fora do seu padrão de uso e/ou utilização indevida que desrespeite qualquer condição presente nestes Termos de Uso, em outras contratações realizadas com a CORA, e/ou na legislação vigente aplicável. Nesses casos, a sua Conta somente será liberada após o esclarecimento e regularização da situação que motivou a limitação.`,
            },
            {
              item: `A CORA reserva-se no direito de, unilateralmente, promover o cancelamento de contas caso verifique alguma irregularidade de natureza grave, nos Termos da Resolução 96 do BACEN,desinteresse comercial e diante da ausência de resposta do Usuário frente às solicitações de documentações comprobatórias.`,
            },
            {
              item: `Ainda, a CORA reserva-se no direito de encerrar qualquer conta na qual se verifique irregularidades nas informações prestadas pelo usuário, julgadas de natureza grave, nos Termos da Resolução 96 do BACEN.`,
            },
            {
              item: `As contas CORA poderão ficar limitadas pelo prazo máximo de 180 (cento e oitenta) dias para análise e investigação da equipe de Segurança.`,
            },
          ],
        },
        {
          head: `Custos relacionados`,
          about: `Aqui trazemos informações sobre as Tarifas aplicáveis aos Serviços.`,
          intro: '',
          items: [
            {
              item: `O uso dos Serviços: Compras em Estabelecimentos, Transferências entre Contas, Pagamento de Boletos e Contas de Consumo prestados diretamente através das Aplicações não gerará a cobrança de quaisquer Tarifas pela CORA. Não obstante, os serviços prestados por meio de Aplicativos de Terceiros através de integração tecnológica com a Cora poderão gerar a cobrança de Tarifas na Conta CORA de Sua Empresa.`,
            },
            {
              item: `A CORA poderá instituir, alterar ou deixar de cobrar quaisquer Tarifas, a qualquer momento, sempre informando Sua Empresa com antecedência mínima de 30 (trinta) dias ou de acordo com a regulamentação aplicável. Neste caso, é facultado a Sua Empresa concordar com tal alteração ou cancelar seu cadastro, rescindindo sua relação contratual com a CORA.`,
            },
            {
              item: `Ocorrendo a falta de pagamento de Tarifa ou qualquer obrigação assumida por Sua Empresa, será promovido registro junto aos órgãos de proteção de crédito, conforme pertinente.`,
            },
          ],
        },
        {
          head: `Encerramento dos serviços`,
          about: `Aqui são apresentadas as regras de encerramento dos Serviços.`,
          intro: '',
          items: [
            {
              item: `Sua Empresa poderá, a qualquer momento, solicitar o encerramento de sua Conta CORA por meio dos Canais de Comunicação, caso em que os Cartões serão bloqueados e a Conta CORA será definitivamente encerrada. A Sua Empresa obrigatoriamente deverá resgatar todos os recursos alocados na Conta CORA, por meio de saque ou transferência para outra conta.`,
            },
            {
              item: `A CORA poderá, a qualquer momento, cancelar a prestação dos Serviços, mediante comunicação a Sua Empresa observado o tempo estabelecido na regulamentação vigente.`,
            },
            {
              item: `A CORA poderá, ainda, cancelar a prestação dos Serviços imediatamente, mediante envio de notificação à Sua Empresa, nas seguintes hipóteses:`,
              child: [
                {
                  item: `A exclusivo critério da CORA, por desinteresse comercial, observando a regulamentação aplicável;`,
                },
                {
                  item: `Caso Sua Empresa viole quaisquer das disposições destes Termos de Uso ou da legislação e regulamentação infralegal pertinente;`,
                },
                {
                  item: `Em caso de má utilização ou uso inadequado dos Serviços por Sua Empresa;`,
                },
                {
                  item: `Caso sejam verificadas operações fora do padrão de uso e Sua Empresa deixe de atender pedido de envio de Informações que comprovem a regularidade dessas operações; e`,
                },
                {
                  item: `Em caso de extinção de Sua Empresa. Neste caso, Sua Empresa deverá retirar o saldo da conta, caso possua, através de saque ou transferência para outra conta bancária.`,
                },
                {
                  item: `Caso Sua Empresa utilize o Cartão de débito e/ou crédito da Sua conta CORA na maquininhas de cartão do Seu próprio estabelecimento, o que caracteriza autofinanciamento.`,
                },
                {
                  item: `A Sua Empresa permanecerá responsável pelo pagamento dos valores e Tarifas devidos com relação às transações realizadas antes do efetivo cancelamento da Conta CORA, sendo permitido à CORA deduzir esses valores do saldo da Conta CORA, se existente, e realizar o envio de boleto referente aos valores ainda em aberto para o e-mail cadastrado na conta.`,
                },
              ],
            },
          ],
        },
        {
          head: `Contato para dúvidas e solicitações`,
          about: `Entenda como podemos nos comunicar.`,
          intro: '',
          items: [
            {
              item: `Qualquer dúvida ou solicitação deverá ser sempre encaminhada aos Canais de Comunicação e/ou de acordo com a forma descrita nestes Termos de Uso, de modo que qualquer outra forma de comunicação com a CORA e/ou suas Parceiras não será reconhecida.`,
            },
            {
              item: `Todas as comunicações realizadas pela CORA e/ou suas Parceiras com Sua Empresa serão feitas por meio das Aplicações ou das Informações de contato indicados por Você, sendo a responsabilidade pelo recebimento e visualização dos comunicados exclusiva de Sua Empresa.`,
            },
          ],
        },
        {
          head: `Informações adicionais`,
          about: `Por fim, trazemos as cláusulas gerais destes Termos de Uso.`,
          intro: '',
          items: [
            {
              item: `Limitação de responsabilidade. Sua Empresa reconhece que todo sistema que depende de rede mundial de computadores está sujeito a falhas e indisponibilidade que, portanto, não acarretarão na responsabilização da CORA e/ou das Parceiras.`,
            },
            {
              item: `Cookies. Ao acessar ou navegar pelas plataformas web da CORA você concorda e estará sujeito ao uso de Cookies de empresas terceiras, conforme a Política de Privacidade.`,
            },
            {
              item: `Cessão. A CORA poderá, a qualquer momento e a seu critério, ceder quaisquer de seus direitos e obrigações previstos nestes Termos de Uso a qualquer pessoa, mediante simples notificação prévia a Sua Empresa, ficando desde já ressalvado que a cessionária continuará a cumprir com todas as obrigações assumidas pela CORA.`,
            },
            {
              item: `Alterações nos Termos de Uso. Estes Termos de Uso podem ser alterados a qualquer tempo e, sempre que ocorrer qualquer modificação, Sua Empresa será informada previamente. Sua Empresa demonstrará que concorda com todas as modificações se continuar a utilizar os Serviços após a alteração dos Termos de Uso. Caso não concorde com os novos Termos de Uso, Sua Empresa poderá rejeitá-los, realizando o cancelamento dos Serviços.`,
            },
            {
              item: `Prazo de Vigência. Estes Termos de Uso e a Política de Privacidade vigerão por prazo indeterminado, a partir de sua aceitação por Sua Empresa.`,
            },
            {
              item: `Lei Aplicável. Estes Termos de Uso e a Política de Privacidade são regidos pelas leis da República Federativa do Brasil.`,
            },
            {
              item: `Dados. A Sua Empresa e os Devedores Solidários desde já autorizam a CORA a coletar, tratar e compartilhar os seus dados cadastrais, financeiros e pessoais com as instituições financeiras, pertencentes ou não ao grupo da CORA, para obtenção dos financiamentos. A CORA declara que segue os ditames da Lei nº 13.709, de 14 de agosto de 2018 (Lei Geral de Proteção de Dados Pessoais) no tratamento dos dados do Usuário, e conforme a Política de Privacidade <a href="/politica-privacidade/" target="_blank">(www.cora.com.br/politica-privacidade)</a>.`,
            },
            {
              item: `A Conta CORA tem como dever adotar procedimentos e controles que permitam confirmar e garantir a identidade do Cliente CORA, a autencidade das informações exigidas, bem como adequar os procedimentos relativos à prevenção à lavagem de dinheiro e ao financiamento do terrorismo, inclusive mediante confrontação das informações com as disponíveis em bancos de dados de caráter público ou privado.`,
              child: [
                {
                  item: `O Cliente CORA obriga-se a cumprir todas as regras sobre prevenção e combate aos crimes de lavagem de dinheiro, de terrorismo e seu financiamento, entre outros, a ocultação de bens especificados pela Lei nº 9.613/1998 e pelo Conselho de Controle de Atividades Financeiras, além de outras legislações e regulamentações aplicáveis às hipóteses, bem como a colaborar de forma efetiva com as autoridades, órgãos de regulação e/ou de fiscalização, no fornecimento de dados e/ou informações, quando legalmente admitidos, inclusive, mas sem limitação, no que tange à prevenção e combate aos crimes de lavagem de dinheiro e ocultação de bens e aos crimes contra crianças e adolescentes, adotando todas as medidas necessárias de sua responsabilidade para coibir tais ilícitos.`,
                },
                {
                  item: `Em atendimento às orientações do Banco Central do Brasil, que visam coibir a lavagem de dinheiro, a CORA se reserva o direito de reportar qualquer transação com dados do Cliente CORA  aos órgãos responsáveis, incluindo ao COAF, em caso de operações suspeitas.`,
                },
                {
                  item: `Nada obstante as obrigações de sigilo aqui previstas, ao aderir a este Termos de Uso, o Usuário expressamente concorda e autoriza a CORA a, sem qualquer ônus, prestar às autoridades competentes, todas as informações que forem solicitadas com relação ao Usuário e/ou Sua Empresa e às operações por Usuário e/ou Sua Empresa executadas sob este Termos de Uso. A CORA poderá, ainda, comunicar ao Conselho de Controle de Atividades Financeiras as movimentações nas Contas CORA que possam estar configuradas no disposto na Lei nº 9.613/1998 e demais normas relativas à lavagem de dinheiro, incluindo a Circular BCB nº 3.978/20, normas e políticas internas da CORA nesse sentido.`,
                },
              ],
            },
            {
              item: `Atos contra a Administração Pública. Ao aderir a estes Termos de Uso, a Sua Empresa e Você obrigam-se a não utilizar a Conta CORA, de forma direta ou indireta, para a prática de ato previsto na Lei nº 12.846, de 1° de agosto de 2013, que atente contra o patrimônio público nacional ou estrangeiro, contra princípios da administração pública ou contra os compromissos internacionais assumidos pelo Brasil.`,
            },
          ],
        },
      ],
    },
  ],
}
