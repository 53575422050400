export const menus = [
  {
    title: 'Conta PJ',
    alt: 'Conta Digital',
    link: '/conta-pj/',
  },
  {
    title: 'Cartão PJ',
    alt: 'Cartão',
    link: '/cartao-pj/',
  },
  {
    title: 'Boletos',
    alt: 'Boletos',
    link: '/conta-pj/boletos/',
  },
  {
    title: 'Gestão de Cobranças',
    alt: 'Gestão de Cobranças',
    link: '/conta-pj/gestao-de-cobrancas/',
  },
  {
    title: 'Contabilidades',
    alt: 'Contabilidades',
    link: 'https://comunidade.cora.com.br/parceiros/',
  },
]
